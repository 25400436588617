p {
  font-size: 20px;
  line-height: 1.5;
}

.about-container {
  padding: 50px 20px;
}

.about-container h2 {
  font-size: 36px;
  margin-top: 30px;
}

.about-container ul {
  margin-top: 20px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.about-container li {
  font-size: 24px;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  cursor: pointer;
}

.about-container li:hover {
  transform: translateY(-5px);
}
