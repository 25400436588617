.header-1 {
    text-align: center;
    color: black;
}
.header-2 {
    text-align: center;
    color: grey;
}
.instance-1 {
    text-align: center;
    background: silver;
    height: 300px;
}
.line-1 {
    border: 2px solid black;
    width: 100%;
}

export {}