.background-image{
    opacity: 0.4;
    text-shadow: 1px 1px black;
}
.text-on-image {
    position: absolute;
    bottom: 75%;
    color:whitesmoke
}
.header-1 {
    text-align: center;
    color: black;
}
